.containerMid {
    width: 100%;
    max-width: 1400px;
    min-width: 1000px;
    overflow: auto;
    margin: auto;
    padding: 10px;
    font-family: "Work Sans", sans-serif;
}
.livefeed-container .newHeader {
    height: 40px;
    background: #00abeb;
    position: relative;
    border-radius: 6px;
    margin: 15px 0;
}

.livefeed-container .dateSelct {background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyBpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBXaW5kb3dzIiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOjFCRjU3NTExNUYxQjExRUNBNUY0OUQ2Rjg2MzQ4RjlGIiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOjFCRjU3NTEyNUYxQjExRUNBNUY0OUQ2Rjg2MzQ4RjlGIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MUJGNTc1MEY1RjFCMTFFQ0E1RjQ5RDZGODYzNDhGOUYiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6MUJGNTc1MTA1RjFCMTFFQ0E1RjQ5RDZGODYzNDhGOUYiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4644a7AAABbklEQVR42mKsqKjgvHPnTq+RkdFRHR2dpV++fGHg4eFh4OfnZ/j8+TMDGxsbw48fPyQWLVrUaWBgMItZXFx8ytq1azNOnTrlaWJickVYWPgmIyMjAwcHB8Pfv39BGgR7enpWbNmyJfDZs2deTHJycqeApv0AmsbZ3Ny84MGDB/7c3NwMTExMDKysrKIdHR0r9uzZ48wABBISEqcZ+vv7GQoLCxN4eXm/A8X+i4iIfJw/f77ruXPneNzd3beDxEDY0dFxA9BAXmYHBweQey9oa2vfv3Llis/79++5gc5zP3HihO/+/fsdQCYDNa62sbGJ//Xr1xdmFxcXBiCDAWjyZXl5+afXr1/3fPv2Ld/jx49lQIrt7e03A3HC9+/fP4OcyQQSBHkSGBIMkpKS85OSkjKAfvgNEndyctoANDkKqPjT//8glzEwgG0AcZiZmRl+/vzJICQkdEFaWvodkP5lZmYWDxT7DJIDqQHZABBgAITilywF74BVAAAAAElFTkSuQmCC) no-repeat right 5px center; width: 140px; border-radius: 4px; margin-left: 10px; border: none; padding: 4px 10px;}

.livefeed-container .newLogo {
    position: absolute;
    left: 15px;
    top: -20px;
    display: flex;
    align-items: center;
}
.livefeed-container .topHd {color: #fff; font-size: 17px; font-weight: 700; padding-left: 15px;}

.livefeed-container .topRight {
    color: #fff;
    height: 40px;
    text-align: right;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.livefeed-container .midPart {
    display: flex;
    padding: 15px 0;
}

.livefeed-container .midLeft {
    width: 260px;
}

.livefeed-container .midCen {
    width: calc(100% - 520px);
    min-width: 600px;
    overflow: auto;
    padding: 0 15px;
    margin: 0 auto;
}

.livefeed-container .midRight {
    width: 260px;
}
.livefeed-container .mb-20 {margin-bottom: 10px;}
.livefeed-container .heading {font-size: 17px; font-weight: 700; padding-bottom: 5px;}
.livefeed-container .heading span {color: #00abeb;}
.livefeed-container .whtBox {border-radius: 6px; border: 1px solid #e8eaeb; background: #fff; box-shadow: 0 3px 3px rgba(0,0,0,.1);}
.livefeed-container .boxList li {border-bottom: 1px solid #d0d0d0; font-size: 14px; padding:5px 10px; display: flex; justify-content: space-between;}
.livefeed-container .boxList li:last-child {border-bottom: none;}
.livefeed-container .boxList li.hd {font-weight: 700;}


.livefeed-container .box {width: 100%; margin: auto; height: 500px; position: relative; overflow: hidden; top: 0; background-color: #FFF;}
.livefeed-container .scroll {position: absolute; width: 100%;}
.livefeed-container .tableSlider {width: 100%; margin: auto; }
.livefeed-container .tableSlider{
    .table{
        tr{
            border: none;
        }
    }
}
.livefeed-container .tableSlider .item {border-radius: 6px; border: 1px solid #e8eaeb; box-shadow: 0 3px 3px rgba(0,0,0,.1);}
.livefeed-container .table {width: 100%; border: 1px solid #eee; border-spacing: 0; font-size: 14px;}
.livefeed-container .table th {background: #f4f4f4; padding: 5px 10px; text-align: left;}
.livefeed-container .table td {padding: 5px 10px; border-top: 1px solid #eee;}
.livefeed-container .latestNews {height: 40px; background: #00abeb; position: relative; border-radius: 6px; padding: 0 20px; display: flex; align-items: center;}
.livefeed-container .latestNews .heading {padding-bottom: 0; color: #fff; min-width: 160px;}
.livefeed-container .newsList {display: flex; white-space: nowrap; list-style: none;}
.livefeed-container .newsList li {color: #fff; font-size: 17px; font-weight: 500; padding: 0 20px; border-right: 1px solid #fff;}

.feedTabs {display: none;}
.no-data-found-mobile{
    display: none;
}
.slick-slider {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}
@media (max-width:1169px) {
   .livefeed-container .midLeft {width: 200px;}
   .livefeed-container .midCen {width: calc(100% - 400px); min-width: 550px; overflow: auto; padding: 0 10px;}
   .livefeed-container .midRight {width: 200px;}
   .livefeed-container .heading {font-size: 17px;}
   .livefeed-container .boxList li {font-size: 14px; padding:10px;}
   .livefeed-container .table th {font-size: 14px;}
   .livefeed-container .table td {font-size: 14px;}
}

// .newsList {
//     overflow: hidden;
//     white-space: nowrap;
//   }
  
//   .newsList li {
//     display: inline-block;
//     padding-right: 20px; /* Add spacing between items if needed */
//     animation: marqueeAnimation 10s linear infinite; /* Adjust timing as needed */
//   }
  
//   @keyframes marqueeAnimation {
//     0% { transform: translateX(0); }
//     100% { transform: translateX(-100%); } /* Adjust the percentage for desired scrolling distance */
//   }

.livefeed-container .marqBox {height: calc(100vh - 170px); overflow: hidden;}

@media (max-width:768px) {

    .containerMid {min-width: 100% !important;}
    .midPart {min-height: calc(100vh - 155px);}
    .livefeed-container .midLeft {display: none; min-width: 100%; width: 100%;}
    .livefeed-container .midRight {display: none; min-width: 100%; width: 100%;}
    .livefeed-container .midCen {min-width: 100%; width: 100%; padding: 0; display: none;}
    .livefeed-container .midLeft.active {display: block;}
    .livefeed-container .midCen.active {display: block;}
    .livefeed-container .midRight.active {display: block;}


    .livefeed-container .topHd {display: none;}
    .livefeed-container .topRight .time {display: none;}

    .feedTabs {display: flex; margin: -5px;}
    .feedTabs li {font-size: 11px; padding: 5px;}
    .feedTabs li a {display: block; background: #eee; padding: 5px 10px;}
    .feedTabs li a:hover, .feedTabs li a.active  {background: #00abeb; color: #fff;}
    .no-data-found-mobile{
        display: flex;
        height: 100%;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin: auto;
        span{
            color: red;
        }
    }
    .livefeed-container .newLogo {left: 10px; top: -3px; width: 45px;}
    .livefeed-container .marqBox {overflow: visible;}
    .livefeed-container .table td {font-size: 11px;}
    .livefeed-container .table th {font-size: 11px;}
    .livefeed-container .tableSlider .item {width: 100%; overflow: auto;}

    .slick-slide.slick-active {z-index: 100 !important;}
}
